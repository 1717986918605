<template>
    <el-card shadow="never" class="aui-card--fill listForOther">
        <div class="mod-sys__post">
            <el-form
                :inline="true"
                :model="dataForm"
                @keyup.enter.native="getDataList(1)"
            >
                <el-form-item prop="appCode">
                    <el-select
                        v-model="dataForm.appCode"
                        placeholder="应用描述"
                    >
                        <el-option
                            v-for="item in codeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="dataForm.name"
                        placeholder="产品名称"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="dataForm.status" placeholder="产品状态">
                        <el-option label="全部" value=""> </el-option>
                        <el-option label="正常" value="1"> </el-option>
                        <el-option label="停用" value="0"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="getDataList(1)">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetHandle()">重置</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="itemEditor({}, 'add')"
                        >新增</el-button
                    >
                </el-form-item>
            </el-form>
            <el-table
                v-loading="dataListLoading"
                :data="dataList"
                border
                style="width: 100%"
            >
                <el-table-column
                    prop="id"
                    label="ID"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="appDesc"
                    label="应用描述"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="name"
                    label="产品名称"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="productCode"
                    label="产品编号"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="productType"
                    label="产品类型"
                    header-align="center"
                    align="center"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.productType == '01' ? '保险' : '非保险'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="cardInitType"
                    label="权益包实例生成方式"
                    header-align="center"
                    align="center"
                    width="150"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.cardInitType == '01' ? '产品' : '被保险人'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="cardValidType"
                    label="权益包有效期模式"
                    header-align="center"
                    align="center"
                    width="150"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.cardValidType == '01'
                                ? '独立权益卡'
                                : '服务跟随'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="creator"
                    label="创建者"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="createDate"
                    label="创建时间"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="updater"
                    label="更新者"
                    header-align="center"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="updateDate"
                    label="更新时间"
                    header-align="center"
                    align="center"
                ></el-table-column>

                <el-table-column
                    label="操作"
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="itemEditor(scope.row, 'editor')"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            :disabled="scope.row.status == 1 ? true : false"
                            @click="itemEditor(scope.row, 'del')"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="page"
                :page-size="10"
                :total="total"
                layout="total, prev, pager, next"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>

            <ModelForOther
                ref="model"
                :itemData="itemData"
                :codeList="codeList"
                @updatTable="updatTable"
            />
        </div>
    </el-card>
</template>

<script>
import ModelForOther from './components/modelForOther';

const urls = {
    page: '/product/external/page',
    del: '/product/external/info',
    getCode: '/sysapp/list', // 获取应用code
};

export default {
    data() {
        return {
            //头部表单筛选
            dataForm: {
                id: '',
                name: '',
                status: '',
                appCode: '',
            },
            itemData: {},
            dataListLoading: false,
            dataList: [],
            page: 1,
            total: 0,
            codeList: [],
        };
    },
    components: {
        ModelForOther,
    },
    mounted() {
        this.getCodeList();
        this.getDataList();
    },

    methods: {
        //表格获取数据
        async getDataList(type) {
            if (type) this.page = 1;
            const { name, id, status, appCode } = this.dataForm;
            let params = {
                limit: 10,
                page: this.page,
                name,
                id,
                status,
                appCode,
            };
            this.dataListLoading = true;
            const res = await this.$http.get(urls.page, { params });
            this.dataListLoading = false;
            const data = res.data;

            const { code = 99, msg = '网络异常' } = data;

            if (code != 0) {
                this.$message.error(msg);
                return false;
            }
            this.dataList = data.data.list;
            this.total = data.data.total;
        },
        async getCodeList() {
            const res = await this.$http.get(urls.getCode);

            const resData = res.data.data || [];
            this.codeList = resData.map((item) => {
                return {
                    label: item.appDesc,
                    value: item.appCode,
                };
            });
        },
        //重置表单
        resetHandle() {
            //Vue中重置data的数据为初始状态
            Object.assign(this.$data, this.$options.data());
            this.getDataList();
            this.getCodeList();
        },
        //单个编辑
        async itemEditor(data, type) {
            if (type == 'del') {
                this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(async () => {
                        const delRes = await this.$http.delete(urls.del, {
                            data: [data.id],
                        });

                        if (delRes.data.code == 0) {
                            this.getDataList();
                        }
                        this.$message({
                            type: 'success',
                            message: '删除成功!',
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        });
                    });

                return;
            }
            this.itemData = data;
            this.$refs.model.dialogVisible = true;
        },
        //页码操作
        pageCurrentChangeHandle(page) {
            this.page = page;
            this.getDataList();
        },

        updatTable() {
            this.resetHandle();
        },
    },
};
</script>
<style lang="scss">
.listForOther {
    .el-form-item__label {
        width: 150px;
    }
}
</style>
