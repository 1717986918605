<template>
    <div>
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            width="75%"
            :before-close="handleClose"
        >
            <div class="img-dialog">
                <!--  -->
                <el-form
                    label-position="right"
                    label-width="150px"
                    :rules="rules"
                    :model="formData"
                    :inline="true"
                    ref="dataForm"
                >
                    <el-form-item label="应用描述" prop="appCode">
                        <el-select
                            v-model="formData.appCode"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in codeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="产品名称" prop="name">
                        <el-input
                            v-model="formData.name"
                            style="width: 194px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="产品编号" prop="productCode">
                        <el-input
                            v-model="formData.productCode"
                            style="width: 194px"
                            disabled
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="产品类型" prop="productType">
                        <el-select
                            v-model="formData.productType"
                            placeholder="请选择"
                        >
                            <el-option label="保险" value="01"> </el-option>
                            <el-option label="非保险" value="02"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="权益包实例生成方式"
                        prop="cardInitType"
                    >
                        <el-select
                            v-model="formData.cardInitType"
                            placeholder="请选择"
                        >
                            <el-option label="产品" value="01"> </el-option>
                            <el-option label="被保险人" value="02"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="权益包有效期模式" prop="cardValidType">
                        <el-select
                            v-model="formData.cardValidType"
                            placeholder="请选择"
                        >
                            <el-option label="独立权益卡" value="01">
                            </el-option>
                            <el-option label="服务跟随" value="02"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-select
                            v-model="formData.status"
                            placeholder="请选择"
                        >
                            <el-option label="停用" :value="0"> </el-option>
                            <el-option label="正常" :value="1"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        prop="appCodeLimit"
                        :label="$t('rightsCard.appCodeLimit')"
                    >
                        <el-select
                            v-model="formData.appCodeLimit"
                            :placeholder="$t('rightsCard.appCodeLimit')"
                            label="appCodeLimit"
                        >
                            <el-option
                                v-for="item in limitList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handelSure">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import editorOption from './editorOption';
const URL_OBJ = {
    save: '/product/external/save',
    update: '/product/external/update',
    getCode: '/sysapp/list', // 获取应用code
};

export default {
    data() {
        return {
            limitList: [],
            rules: {
                appCode: [
                    {
                        required: true,
                        message: '请选择应用code',
                        trigger: 'change',
                    },
                ],
                name: [
                    {
                        required: true,
                        message: '请输入商品名称',
                        trigger: 'blur',
                    },
                ],
                productType: [
                    {
                        required: true,
                        message: '请选择产品类型',
                        trigger: 'change',
                    },
                ],
                cardInitType: [
                    {
                        required: true,
                        message: '请选择权益包实例生成方式',
                        trigger: 'change',
                    },
                ],
                cardValidType: [
                    {
                        required: true,
                        message: '请选择权益包有效期模式',
                        trigger: 'change',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: '请选择权益包状态',
                        trigger: 'change',
                    },
                ],
            },
            action: `${
                window.SITE_CONFIG['apiURL']
            }/sys/oss/upload?token=${sessionStorage.getItem('token')}`,
            dialogVisible: false,
            formData: {
                appCode: '', // 应用code
                name: '', // 产品名称'
                productCode: '', // 产品编号
                productType: '', // 产品类型 01：保险 02：非保险
                cardInitType: '', // 权益包实例生成方式 01：产品 02：被保险人
                cardValidType: '', // 权益包有效期模式 01：独立权益卡 02：服务跟随
                status: '', // 0 停用 1 正常
                appCodeLimit: '', //
            },
            // formData: {
            //   id: '', //商品id
            //   name: '', //商品名称
            //   content: '', //商品描述
            //   price: '', //公示价格
            //   discountPrice: '', //折后价格
            //   productType: '', //商品类型
            //   relateId: '', //权益包名称
            //   status: '', //商品状态
            //   showScene: '', //显示状态
            //   shareTitle: '', //分享标题
            //   shareDescription: '', //分享描述
            //   productDetails: '', //商品详情
            //   productPicture: '',
            //   productListPicture: '', //https://duihui.duoduocdn.com/zuqiu/bierbae.png
            //   sharePicture: '',
            // },
            editorOption,
        };
    },
    props: {
        itemData: {
            type: Object,
            require: false,
        },
        codeList: {
            type: Array,
            require: false,
        },
    },
    watch: {
        watchItem: {
            immediate: true,
            deep: true,
            handler(newVal) {
                let deepObj = {};
                if (Object.keys(newVal).length) {
                    deepObj = cloneDeep(newVal);
                }
                this.formData = deepObj;
            },
        },
    },
    mounted() {
        this.getCodeList();
    },
    methods: {
        async getCodeList() {
            const res = await this.$http.get(URL_OBJ.getCode);

            const resData = res.data.data || [];
            this.limitList = resData.map((item) => {
                return {
                    label: item.appDesc,
                    value: item.appCode,
                };
            });
            console.log(this.limitList, 9099);
        },
        handelSure: debounce(
            function () {
                this.$refs['dataForm'].validate(async (valid) => {
                    if (!valid) return;

                    //编辑
                    if (this.watchItem.id) {
                        //
                        const res = await this.$http.post(
                            URL_OBJ.update,
                            this.formData
                        );
                        if (res.data.code != 0) {
                            this.$message.error(res.data.msg);
                            return;
                        }
                        this.$emit('updatTable');
                    } else {
                        //新增

                        const res = await this.$http.post(
                            URL_OBJ.save,
                            this.formData
                        );
                        if (res.data.code != 0) {
                            this.$message.error(res.data.msg);
                            return;
                        }
                        this.$emit('updatTable');
                    }
                    this.dialogVisible = false;
                });
            },
            1000,
            { leading: true, trailing: false }
        ),
        handleClose() {
            this.$confirm('确认关闭？')
                .then(async (_) => {
                    // done();
                    this.dialogVisible = false;
                    this.$refs['formData'].resetFields();
                    // Object.assign(this.$data, this.$options.data());
                })
                .catch((_) => {});
        },
        bannerSuccess(res) {
            this.$set(this.formData, 'productPicture', this.initImg(res));

            // this.formData.productPicture = URL.createObjectURL(file.raw);
        },
        listSuccess(res) {
            this.$set(this.formData, 'productListPicture', this.initImg(res));
        },
        shareSuccess(res) {
            this.$set(this.formData, 'sharePicture', this.initImg(res));
        },

        initImg(res) {
            const { code = 99, data = {}, msg = '网络异常' } = res;
            if (code != 0) {
                this.$message.error(msg);
                return false;
            }
            return data.src;
        },
        onEditorChange({ _, html, text }) {
            console.log(html, text);
            this.formData.productDetails = html;
        },
    },
    computed: {
        watchItem() {
            return this.itemData;
        },
    },
};
</script>
